









































































import { Component, Prop, Vue } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";


@Component({
  name: "UnselectedList",
  components: {
    PagedTableView,
    AbSelect,
    ExportButton
  },
})
export default class UnselectedList extends Vue {
  @Prop() trainId?: number;


  queryForm = {
    surName: "",
    phone: undefined,
    status:undefined,
    trainId: 0,
  };
  exportFieldList = [
    "志愿者编号",
    "姓名",
    "性别",
    "手机号",
    "签到时间"
  ];
  created(){
      this.queryForm.trainId = this.trainId!
  }

  fetchData(params: any) {
    return api.trainApply.getTrainCheckInRecord({
      ...params,
      surName: this.queryForm.surName,
      phone: this.queryForm.phone,
      trainId: this.trainId,
    });
  }
}
