











































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import CheckedIn from "@/views/train/trainApply/component/checkedIn.vue"
import ExportButton from "@/components/ExportButton/index.vue";
import {getActivityApplyStatus, getQueryApplyStatus} from "@/utils/enumToSelectItems";
import { AuditFlowScope } from "@/api/appService";
import MakeUp from "@/views/train/trainApply/component/makeUp.vue";
import UserSelect from "@/views/train/trainApply/component/UserSelect.vue";


@Component({
  name: "UnselectedList",
  components: {
    UserSelect,
    MakeUp,
    PagedTableView,
    AbSelect,
    CheckedIn,
    ExportButton
  },
})
export default class UnselectedList extends Vue {
  @Prop() trainId?: number;

  editLinkId = 0;

  queryForm = {
    surName: "",
    phone: undefined,
    status:undefined,
    queryStatus:undefined,
    trainId: 0,
  };
  exportFieldList = [
    "志愿者编号",
    "姓名",
    "性别",
    "手机号",
    "居住地",
    "报名时间",
    "志愿者星级",
    "志愿者积分",
    "签到次数",
    "状态"
  ];

  statusList = getActivityApplyStatus();
  queryApplyStatusList = getQueryApplyStatus();

  fetchData(params: any) {
    return api.trainApply.getAll({
      ...params,
      surName: this.queryForm.surName,
      phone: this.queryForm.phone,
      status:this.queryForm.status,
      queryStatus:this.queryForm.queryStatus,
      trainId: this.trainId,
    });
  }

  created(){
    this.queryForm.trainId =  this.trainId!
  }

  // 新建
  handleCheckIn(item:any) {
    this.editLinkId = item.id!;
    (this.$refs.editForm as any).show = true;
  }
  // 新建
  handleMakeUp(item:any) {
    this.editLinkId = item.id!;
    (this.$refs.makeUpForm as any).show = true;
  }
  handleSelect(item:any) {
    this.trainId = this.queryForm.trainId ;
    (this.$refs.userSelect as any).show = true;
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.TrainApply,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  handleDetail(item: any) {
    this.$router.push({name: 'trainApplyDetail', params: {'id': item.id!.toString()}})
  }
}
